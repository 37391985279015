<template>
  <div v-if="loading" class="page-container">
    <div class="container-title-block">
      <div class="title-block-text">Группы</div>
      <div class="title-block-search">
        <SearchInput class="ml-4" v-model="search" label="Поиск групп" />
      </div>
    </div>
    <div class="container-subtitle-block">
      <v-btn
        depressed
        class="button-blue"
        @click="dialogAddGroup = true"
      >
        Добавить группу
      </v-btn>
      <div class="subtitle-block-count">Всего групп: {{ items.length }}</div>
    </div>
    <div class="container-table">
      <Table
        :items="search && search.length != 0 ? searchingItems : items"
        :headers="headers"
        headerFixed
        @click:row="goToDetail"
      >
        <template v-slot:[`item.title`]="{ item }">
          <div class="d-flex align-center">
            <div>
              {{ item.group.title }}
            </div>
            <v-icon v-if="item.group.isSmart" class="ml-1" size="16"
              >mdi-sync-circle</v-icon
            >
          </div>
        </template>
        <template v-slot:[`item.users`]="{ item }">
          {{ item.attributes.userGroup }}
        </template>
        <template v-slot:[`item.tags`]="{ item }">
          {{ item.attributes.tagGroup }}
        </template>
        <template v-slot:[`item.course`]="{ item }">
          {{ item.attributes.courseGroup }}
        </template>
        <template v-slot:[`item.tests`]="{ item }">
          {{ item.attributes.testGroup }}
        </template>
      </Table>
    </div>
    <Dialog
      v-model="dialogAddGroup"
      titleText="Создание группы"
      :refForm="'form'"
      @click:success="addItem"
      :mainItems="[
        {
          type: 'text',
          label: 'Название группы',
          valid: true,
          value: group.title,
          vmodel: (e) => (group.title = e),
        },
      ]"
    >
      <template v-slot:buttonDelete>
        <SwitchButtons v-model="group.isSmart" :items="tabsItems" />
      </template>
    </Dialog>
  </div>
</template>
<script>
import SearchInput from "@/components/main/inputs/SearchInput";
import SwitchButtons from "@/components/main/SwitchButtons";
export default {
  components: {
    SearchInput,
    SwitchButtons,
  },
  data: () => ({
    loading: false,
    tabsItems: [
      { text: "Стандартная", value: false },
      { text: "Smart", value: true },
    ],
    search: "",
    items: [],
    dialogAddGroup: false,
    group: {
      title: "",
      isSmart: false,
    },
    headers: [
      {
        text: "Название группы",
        align: "flex-left",
        sortable: false,
        value: "title",
      },
      {
        text: "Сотрудники",
        align: "flex-left",
        sortable: false,
        value: "users",
      },
      {
        text: "Теги",
        align: "flex-left",
        sortable: false,
        value: "tags",
      },
      {
        text: "Доступно курсов",
        align: "flex-left",
        sortable: false,
        value: "course",
      },
      {
        text: "Назначено курсов",
        align: "flex-left",
        sortable: false,
        value: "course",
      },
      {
        text: "Назначено тестов",
        align: "flex-left",
        sortable: false,
        value: "tests",
      },
    ],
  }),
  computed: {
    searchingItems() {
      return this.items.filter((i) =>
        i.group.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    goToDetail(item) {
      this.$router.push({ name: "Group", params: { id: item.group.id } });
    },
    addItem(method) {
      this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/group",
          {
            title: this.group.title,
            isSmart: this.group.isSmart,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(async () => {
          await this.getItems();
          method();
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    async getItems() {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/group/list", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.items = res.data;
          this.loading = true;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
  },
  created() {
    this.getItems();
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  padding: 40px 64px 0px 64px;
  height: 100%;
  width: 100%;
  position: relative;
  .container-title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-block-text {
      color: var(--app-black);
      font-size: 28px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0px;
    }
    .title-block-search {
      width: 300px;
      min-width: 300px;
    }
  }
  .container-subtitle-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0px 12px 0px;
    position: relative;
    .buttons-block-search {
      min-width: 300px;
    }
    .subtitle-block-count {
      font-size: 16px;
      color: var(--app-grey-3);
    }
  }
  .container-table {
    height: calc(100vh - 180px);
    .table-icon {
      filter: invert(44%) sepia(35%) saturate(0%) hue-rotate(179deg)
        brightness(91%) contrast(80%);
      margin: 0px 4px;
    }
  }
}
</style>