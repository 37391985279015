var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"page-container"},[_c('div',{staticClass:"container-title-block"},[_c('div',{staticClass:"title-block-text"},[_vm._v("Группы")]),_c('div',{staticClass:"title-block-search"},[_c('SearchInput',{staticClass:"ml-4",attrs:{"label":"Поиск групп"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"container-subtitle-block"},[_c('v-btn',{staticClass:"button-blue",attrs:{"depressed":""},on:{"click":function($event){_vm.dialogAddGroup = true}}},[_vm._v(" Добавить группу ")]),_c('div',{staticClass:"subtitle-block-count"},[_vm._v("Всего групп: "+_vm._s(_vm.items.length))])],1),_c('div',{staticClass:"container-table"},[_c('Table',{attrs:{"items":_vm.search && _vm.search.length != 0 ? _vm.searchingItems : _vm.items,"headers":_vm.headers,"headerFixed":""},on:{"click:row":_vm.goToDetail},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(" "+_vm._s(item.group.title)+" ")]),(item.group.isSmart)?_c('v-icon',{staticClass:"ml-1",attrs:{"size":"16"}},[_vm._v("mdi-sync-circle")]):_vm._e()],1)]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.userGroup)+" ")]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.tagGroup)+" ")]}},{key:"item.course",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.courseGroup)+" ")]}},{key:"item.tests",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.testGroup)+" ")]}}],null,true)})],1),_c('Dialog',{attrs:{"titleText":"Создание группы","refForm":'form',"mainItems":[
      {
        type: 'text',
        label: 'Название группы',
        valid: true,
        value: _vm.group.title,
        vmodel: function (e) { return (_vm.group.title = e); },
      } ]},on:{"click:success":_vm.addItem},scopedSlots:_vm._u([{key:"buttonDelete",fn:function(){return [_c('SwitchButtons',{attrs:{"items":_vm.tabsItems},model:{value:(_vm.group.isSmart),callback:function ($$v) {_vm.$set(_vm.group, "isSmart", $$v)},expression:"group.isSmart"}})]},proxy:true}],null,false,2243044050),model:{value:(_vm.dialogAddGroup),callback:function ($$v) {_vm.dialogAddGroup=$$v},expression:"dialogAddGroup"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }